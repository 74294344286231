<template>
  <div v-if="location==='home'">
    <search-up-panel/>
    <search-down-panel/>
  </div>
  <section v-else class="directflightsonly_area"
    :class="{'fix': device==='desktop' && isScroll && !showFixSearchBox && !isShowFilterBox, 'fix-box': showFixSearchBox}">
    <div class="container px-5">
      <div class="directflightsonly_innerbox" id="main-filter-div">
        <search-up-panel/>
        <search-down-panel/>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'sabre-search-pane',
  props: {
    location: {
      type: String,
      default: '',
    },
  },
  components: {
    searchUpPanel: () => import('./searchUpPanel'),
    searchDownPanel: () => import('./searchDownPanel'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
    }),
  },
  data() {
    return {
      isScroll: false,
      showFixSearchBox: false,
      isShowFilterBox: false,
    };
  },
  mounted() {
    this.$root.$on('show-fix-search-panel', this.showFixSearchPanel);
    this.$root.$on('show-fix-filter-panel', this.showFixFilterPanel);
    document.querySelector('.st-content').addEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll(event) {
      this.isScroll = Number(event.target.scrollTop) > 150;
      this.showFixSearchBox = false;
      this.isShowFilterBox = false;
    },
    showFixSearchPanel(t) {
      this.showFixSearchBox = t;
    },
    showFixFilterPanel(t) {
      this.isShowFilterBox = t;
    },
  },
};

</script>

<style>
  .directflightsonly_area .btn-group.opacity-button {
    opacity: 0.3;
  }
  .directflightsonly_area.fix-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 1px 1px 20px gray;
  }
</style>
